import { addAction, INIT } from '@situation/setdesign.util';

/* global ScrollTrigger */
gsap.registerPlugin(ScrollTrigger);
addAction(INIT, () => {
    const treeRoots = document.body.classList.contains('cast-creative');
    /* global gsap */
    // This is used for GSAP breakpoints
    // let matchMedia = gsap.matchMedia();
    // --- Top Section Background ---
    gsap.to('.top-section .content-background-image__img', {
        scrollTrigger: {
            trigger: '.top-section',
            scrub: true,
            start: 'top top',
            end: 'bottom-=350px top',
        },
        scale: 1.1,
        ease: 'none',
    });

    // --- Left Tree ---
    gsap.to('#left-tree img', {
        scrollTrigger: {
            trigger: '.top-section',
            scrub: true,
            start: 'top top',
            end: 'bottom-=350px top',
        },
        x: -150,
        scale: 1.25,
        ease: 'none',
    });

    // --- Right Tree ---
    gsap.to('#right-tree img', {
        scrollTrigger: {
            trigger: '.top-section',
            scrub: true,
            start: 'top top',
            end: 'bottom-=350px top',
        },
        x: 250,
        scale: 1.25,
        ease: 'none',
    });

    // --- Idina ---
    gsap.to('#idina img', {
        scrollTrigger: {
            trigger: '.top-section',
            scrub: true,
            start: 'top top',
            end: 'bottom-=350px top',
        },
        scale: 1.25,
        ease: 'none',
    });

    // --- Bottom Section Background ---
    gsap.to('#right-branch img', {
        scrollTrigger: { trigger: '#left-branch img', scrub: true },
        filter: 'blur(0rem)',
        y: 750,
        ease: 'none',
    });

    // --- Left Branch ---
    gsap.to('#left-branch img', {
        scrollTrigger: { trigger: '#left-branch img', scrub: true },
        filter: 'blur(0rem)',
        y: 500,
        ease: 'none',
    });

    // Removing wrapping responsive breakpoints
    // matchMedia.add("(min-width: 767px)", () => {
    gsap.to('.body-bg', {
        scrollTrigger: { trigger: '.position--static', scrub: true },
        backgroundPosition: treeRoots ? '50% 30%' : '50% 100%',
        ease: 'none',
    });
    // });
});
